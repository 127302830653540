var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--how-it-works" },
    [
      _c("the-layout-header", { attrs: { title: _vm.$t("howItWorks.title") } }),
      _c("div", { staticClass: "py-7" }),
      _c(
        "v-container",
        [
          _c(
            "v-sheet",
            {
              staticClass: "mx-auto mb-10",
              attrs: { color: "transparent", "max-width": "500px" },
            },
            [
              _c("h1", { staticClass: "text-sm-center" }, [
                _vm._v(_vm._s(_vm.$t("home.features.heading"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("how-it-works-send"),
      _c("how-it-works-swap"),
      _c("how-it-works-dapps-center"),
      _c("how-it-works-tokens"),
      _c("how-it-works-more"),
      _c("app-get-started"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }